export class Tracking {
	firstPageLoad = true;

	pageView(pageTitle) {
		if (!this.firstPageLoad) {
			window.dataLayer.push({ 'event': 'virtualPageView', pageTitle });
		}
		this.firstPageLoad = false;
	}

	event(name) {
		window.dataLayer.push({ 'event': name });
	}
}
export const tracking = new Tracking();
