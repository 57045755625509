// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--OC1Dq";
export var container = "footer-module--container--4mD4c";
export var logoWrapper = "footer-module--logo-wrapper--OI1gK";
export var logo = "footer-module--logo--TnP1F";
export var sections = "footer-module--sections--pYwXC";
export var sectionsInner = "footer-module--sections-inner--kppIX";
export var nav = "footer-module--nav--T251U";
export var navList = "footer-module--nav-list--jqGu9";
export var homeLink = "footer-module--home-link--Ycc6D";
export var navListItemLink = "footer-module--nav-list-item-link--3Apdl";
export var socialListItemLink = "footer-module--social-list-item-link--Dyhfr";
export var social = "footer-module--social--IlHoh";
export var socialInner = "footer-module--social-inner--0l88Y";
export var socialList = "footer-module--social-list--L1MZ8";
export var titleWrapper = "footer-module--title-wrapper--chMfm";
export var title = "footer-module--title--MW5br";
export var linksContainer = "footer-module--links-container--u9NpD";
export var links = "footer-module--links--mF1BQ";
export var link = "footer-module--link--8lGgU";