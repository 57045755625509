import React from "react";
import { Link } from "gatsby";
import { useOnClickOutside } from "./hooks/useOnClickOutside";
import classnames from "classnames";
import * as styles from "./menu.module.css";
import logoImage from "./images/logo.png";
import { paths } from "../../../../../../constants/paths";

const Menu = ({ isOpen, closeMenu }) => {
  const menuWrapperRef = React.useRef();

  useOnClickOutside(menuWrapperRef, closeMenu);

  return (
    <div className={classnames(styles.container, { [styles.open]: isOpen })}>
      <div ref={menuWrapperRef} className={styles.menu}>
        <div className={styles.header}>
          <img src={logoImage} className={styles.logo} alt="Virgin Galactic" />
        </div>
        <div className={styles.navWrapper}>
          <nav className={styles.nav}>
            <ul className={styles.navLinks}>
              <li className={styles.navLinkItem}>
                <Link
                  to={paths.home}
                  className={classnames(
                    styles.navLink,
                    "gtm-wb-header-menu-why-we-go"
                  )}
                  onClick={closeMenu}
                >
                  Why We Go
                </Link>
              </li>
              <li className={styles.navLinkItem}>
                <Link
                  to={paths.spaceflight}
                  className={classnames(
                    styles.navLink,
                    "gtm-wb-header-menu-spaceflight"
                  )}
                  onClick={closeMenu}
                >
                  Spaceflight
                </Link>
              </li>
              <li className={styles.navLinkItem}>
                <Link
                  to={paths.preFlight}
                  className={classnames(
                    styles.navLink,
                    "gtm-wb-header-menu-pre-flight"
                  )}
                  onClick={closeMenu}
                >
                  Pre-Flight
                </Link>
              </li>
              <li className={styles.navLinkItem}>
                <Link
                  to={paths.membership}
                  className={classnames(
                    styles.navLink,
                    "gtm-wb-header-menu-membership"
                  )}
                  onClick={closeMenu}
                >
                  Membership
                </Link>
              </li>
              <li className={styles.navLinkItem}>
                <Link
                  to={paths.newPerspective}
                  className={classnames(
                    styles.navLink,
                    "gtm-wb-header-menu-new-perspective"
                  )}
                  onClick={closeMenu}
                >
                  New Perspective
                </Link>
              </li>
              {/*<li className={styles.navLinkItem}>*/}
              {/*  <Link to={paths.pricing} className={classnames(styles.navLink, 'gtm-wb-header-menu-pricing')} onClick={closeMenu}>*/}
              {/*    Pricing*/}
              {/*  </Link>*/}
              {/*</li>*/}
            </ul>
          </nav>
          <div className={styles.emailLinkWrapper}>
            <a
              className={classnames(
                styles.emailLink,
                "gtm-wb-header-menu-email-pdf"
              )}
              href={`mailto:?body=Download the Virgin Galactic Brochure https://virgingalactic.com/download-brochure`}
            >
              Email PDF
            </a>
          </div>
          {/*<div className={styles.reserveLinkWrapper}>*/}
          {/*  <GradientLink className='gtm-wb-header-menu-sign-up' to='https://www.virgingalactic.com/fly-with-us'>SIGN UP</GradientLink>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

export default Menu;
