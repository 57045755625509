import React from 'react';
import 'normalize.css';
import '../../styles/fonts.css';
import '../../styles/common.css';
import '../../styles/osano.css';
import '../../styles/variables.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Seo from './components/Seo';
import * as styles from './layout.module.css';
import { tracking } from '../../utils/tracking';

const Layout = ({ pageTitle, description, children }) => {

  React.useEffect(() => tracking.pageView(pageTitle), [pageTitle]);

  return (
    <div className={styles.container}>
      <Seo pageTitle={pageTitle} description={description} />
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
}

export default Layout;
