import React from 'react';
import * as styles from './footer.module.css';
import { Link } from 'gatsby';
// import mobileLogoIcon from './images/mobile-logo.svg';
import { paths } from '../../../../constants/paths';
import classnames from 'classnames';


const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        {/*TODO update this logo later*/}
        {/*<div className={styles.logoWrapper}>*/}
        {/*  <Link to={paths.home}>*/}
        {/*    <source srcSet={mobileLogoIcon} media='(max-width: 768px)'/>*/}
        {/*    <img src={mobileLogoIcon} className={styles.logo} alt='Virgin Galactic Logo'/>*/}
        {/*  </Link>*/}
        {/*</div>*/}
        <div className={styles.sections}>
          <div className={styles.sectionsInner}>
            <div className={styles.titleWrapper}>
              <h3 className={styles.title}>SECTIONS</h3>
            </div>
            <nav className={styles.nav}>
              <ul className={styles.navList}>
                <li>
                  <Link className={classnames(styles.navListItemLink, 'gtm-wb-footer-sections-why-we-go')} to={paths.home}>Why We Go</Link>
                </li>
                <li>
                  <Link className={classnames(styles.navListItemLink, 'gtm-wb-footer-sections-spaceflight')} to={paths.spaceflight}>Spaceflight</Link>
                </li>
                <li>
                  <Link className={classnames(styles.navListItemLink, 'gtm-wb-footer-sections-pre-flight')} to={paths.preFlight}>Pre-Flight</Link>
                </li>
                <li>
                  <Link className={classnames(styles.navListItemLink, 'gtm-wb-footer-sections-membership')} to={paths.membership}>Membership</Link>
                </li>
                <li>
                  <Link className={classnames(styles.navListItemLink, 'gtm-wb-footer-sections-new-perspective')} to={paths.newPerspective}>New Perspective</Link>
                </li>
                {/*<li>*/}
                {/*  <Link className={classnames(styles.navListItemLink, 'gtm-wb-footer-sections-pricing')} to={paths.pricing}>Pricing</Link>*/}
                {/*</li>*/}
              </ul>
            </nav>
            <a href='https://www.virgingalactic.com' className={classnames(styles.homeLink, 'gtm-wb-footer-other-pages-vg-homepage')} target='_blank' rel='noopener noreferrer'>
              Virgin Galactic Homepage
            </a>
          </div>
        </div>
        <div className={styles.social}>
          <div className={styles.socialInner}>
            <div className={styles.titleWrapper}>
              <h3 className={styles.title}>SOCIAL</h3>
            </div>
            <ul className={styles.socialList}>
              <li>
                <a href='https://twitter.com/virgingalactic' className={classnames(styles.socialListItemLink, 'gtm-wb-footer-social-twitter')} target='_blank' rel='noopener noreferrer'>
                  Twitter
                </a>
              </li>
              <li>
                <a href='https://www.facebook.com/VirginGalactic' className={classnames(styles.socialListItemLink, 'gtm-wb-footer-social-facebook')} target='_blank' rel='noopener noreferrer'>
                  Facebook
                </a>
              </li>
              <li>
                <a href='https://www.youtube.com/channel/UClcvOr7LV8tlJwJvkNMmnKg' className={classnames(styles.socialListItemLink, 'gtm-wb-footer-social-youtube')} target='_blank' rel='noopener noreferrer'>
                  YouTube
                </a>
              </li>
              <li>
                <a href='https://www.instagram.com/virgingalactic' className={classnames(styles.socialListItemLink, 'gtm-wb-footer-social-instagram')} target='_blank' rel='noopener noreferrer'>
                  Instagram
                </a>
              </li>
              <li>
                <a href='https://www.linkedin.com/company/virgin-galactic/' className={classnames(styles.socialListItemLink, 'gtm-wb-footer-social-linkedin')} target='_blank' rel='noopener noreferrer'>
                  Linkedin
                </a>
              </li>
              <li>
                <a href='https://www.tiktok.com/@virgingalactic?' className={classnames(styles.socialListItemLink, 'gtm-wb-footer-social-tiktok')} target='_blank' rel='noopener noreferrer'>
                  Tiktok
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.linksContainer}>
          <ul className={styles.links}>
            <li>
              <a href='https://www.virgingalactic.com/privacy'
                 className={classnames(styles.link, 'gtm-wb-footer-legal-privacy')}
                 target='_blank'
                 rel='noopener noreferrer'
              >
                PRIVACY POLICY
              </a>
            </li>
            <li>
              <a href='https://www.virgingalactic.com/terms'
                 className={classnames(styles.link, 'gtm-wb-footer-legal-terms')}
                 target='_blank'
                 rel='noopener noreferrer'
              >
                TERMS & CONDITIONS
              </a>
            </li>
            <li>
              <a href='https://www.virgingalactic.com/cookie-notice'
                 className={styles.link}
                 target='_blank'
                 rel='noopener noreferrer'
              >
                COOKIE NOTICE
              </a>
            </li>
            <li>
              <Link 
                 to={paths.adaStatement}
                 className={styles.link}
              >
                ADA STATEMENT
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
