import * as React from 'react';
import { Link } from 'gatsby';
import Menu from './components/Menu';
import * as styles from './header.module.css';
import logoIcon from './images/logo.svg';
import { paths } from '../../../../constants/paths';
import { isDesktop } from 'react-device-detect';
import classnames from 'classnames';
import DownloadButton from '../../../DownloadButton';
import GradientLink from '../../../GradientLink';

const Header = () => {
  const [isMenuOpened, setIsMenuOpened] = React.useState(false);

  const closeMenu = () => {
    if (!isDesktop) {
      document.body.style.overflow = 'visible';
      document.body.style.maxHeight = 'none';
    }

    setIsMenuOpened(false);
  };
  const openMenu = () => {
    if (!isDesktop) {
      document.body.style.overflow = 'hidden';
      document.body.style.maxHeight = '100vh';
    }

    setIsMenuOpened(true);
  };

  return (
    <header className={styles.container}>
      <Link to={paths.home} className={classnames(styles.logoWrapper, 'gtm-wb-header-vg-logo-brochure')} aria-label='Home page'>
        <img src={logoIcon} className={styles.logo} alt='Virgin Galactic Logo' />
      </Link>
      <nav className={styles.nav}>
        <ul className={styles.navLinks}>
          <li className={styles.navLinkItem}>
            <Link to={paths.home} className={classnames(styles.navLink, 'gtm-wb-header-sections-why-we-go')} activeClassName={styles.active}>
              Why We Go
            </Link>
          </li>
          <li className={styles.navLinkItem}>
            <Link to={paths.spaceflight} className={classnames(styles.navLink, 'gtm-wb-header-sections-spaceflight')} activeClassName={styles.active}>
              Spaceflight
            </Link>
          </li>
          <li className={styles.navLinkItem}>
            <Link to={paths.preFlight} className={classnames(styles.navLink, 'gtm-wb-header-sections-pre-flight')} activeClassName={styles.active}>
              Pre-Flight
            </Link>
          </li>
          <li className={styles.navLinkItem}>
            <Link to={paths.membership} className={classnames(styles.navLink, 'gtm-wb-header-sections-membership')} activeClassName={styles.active}>
              Membership
            </Link>
          </li>
          <li className={styles.navLinkItem}>
            <Link to={paths.newPerspective} className={classnames(styles.navLink, 'gtm-wb-header-sections-new-perspective')} activeClassName={styles.active}>
              New Perspective
            </Link>
          </li>
          {/*<li className={styles.navLinkItem}>*/}
          {/*  <Link to={paths.pricing} className={classnames(styles.navLink, 'gtm-wb-header-sections-pricing')} activeClassName={styles.active}>*/}
          {/*    Pricing*/}
          {/*  </Link>*/}
          {/*</li>*/}
        </ul>
      </nav>
      {/*<div className={styles.actionsWrapper}>*/}
      {/*  <div className={styles.downloadBrochureWrapper}>*/}
      {/*    <DownloadButton className='gtm-wb-header-brochure-download-brochure' link='https://www.virgingalactic.com/download-brochure'>*/}
      {/*      Download Brochure*/}
      {/*    </DownloadButton>*/}
      {/*  </div>*/}
      {/*  <div className={styles.buttonWrapper}>*/}
      {/*    <GradientLink className='gtm-wb-header-application-sign-up' to='https://www.virgingalactic.com/fly-with-us'>SIGN UP</GradientLink>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className={styles.menuButtonWrapper}>
        <button type='button' aria-label='Open menu' className={classnames(styles.menuButton, 'gtm-wb-header-menu-hamburger')} onClick={openMenu}>
          <div />
        </button>
      </div>
      <Menu isOpen={isMenuOpened} closeMenu={closeMenu} />
    </header>
  )
};

export default Header;
